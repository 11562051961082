import { createSlice } from '@reduxjs/toolkit';
import { clearTokens } from '../../utils';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: null,
	},
	reducers: {
		logIn(state, action) {
			state.user = action.payload;
		},
		logOut(state) {
			clearTokens();
			window.location.href = process.env.REACT_APP_SITE_URL;
		},
	},
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
